let svgLoaderRunning = false;

/**
 * create observer onLoad
 *
 * @returns {void} Nothing.
 */
window.addEventListener("load", (event) => {
  const svgs = document.querySelectorAll('svg[data-url]');
  if (svgs.length) {
    createObserver(svgs);
  }
}, false);

/**
 * Creating the intersection observer
 *
 * @returns {void} Nothing.
 */
const createObserver = function (svgs) {
  let observer;
  let options = {
    root: null,
    rootMargin: "24px",
    threshold: 0
  };
  svgLoaderRunning = true;

  observer = new IntersectionObserver(handleIntersect, options);
  svgs.forEach(function (svg) {
    observer.observe(svg);
  });
};

/**
 * Handling intersection changes
 *
 * @returns {void} Nothing.
 */
const handleIntersect = function (entries, observer) {
  entries.forEach((svg) => {
    if (svg.isIntersecting) {
      // Grab the URL and
      let url = svg.target.getAttribute('data-url');

      if (url) {
        // let another function handle the actual fetching so we can use the async modifier.
        fetchSVG(url, svg.target);
      }

      //delete the attribute we no longer need it.
      svg.target.removeAttribute('data-url');

      observer.unobserve(svg.target);
    }
  });
  svgLoaderRunning = false;
};

/**
 * Re-Trigger the SVG-Loader if the markup of the site changes somewhere.
 */
(() => {
  const body = document.querySelector('body');
  const bodyObserverConfig = { attributes: false, childList: true, subtree: true };

  const handleBodyChange = () => {
    if (svgLoaderRunning) {
      return;
    }

    const svgs = document.querySelectorAll('svg[data-url]:not([data-autoload="0"])');
    createObserver(svgs);
  };
  (new MutationObserver(handleBodyChange)).observe(body, bodyObserverConfig);
})();
